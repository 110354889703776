import { useLazyQuery } from '@apollo/client'

import {
  FIND_UNIQUE_PROSPECT,
  FindUniqueProspectPayload,
  FindUniqueProspectVariables,
} from 'queries/prospect'

const useProspectUniqueLazy = () => {
  const [getProspect, { data: { prospect } = { prospect: null }, loading }] =
    useLazyQuery<FindUniqueProspectPayload, FindUniqueProspectVariables>(
      FIND_UNIQUE_PROSPECT,
    )

  const fetchProspect = (args: Parameters<typeof getProspect>[0]) =>
    new Promise<FindUniqueProspectPayload>((resolve, reject) => {
      getProspect({
        ...args,
        onCompleted: (data) => {
          args?.onCompleted?.(data)
          resolve(data)
        },
        onError: (error) => {
          args?.onError?.(error)
          reject(error)
        },
      })
    })

  return {
    fetchProspect,
    prospect,
    loading,
  }
}

export default useProspectUniqueLazy
