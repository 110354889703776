import { useState } from 'react'

import { useMutation } from '@apollo/client'

import {
  SEND_DASHBOARD_LINK,
  SendProspectDashboardLinkPayload,
  SendProspectDashboardLinkVariables,
} from 'queries/prospect'

export const useProspectSendDashboardLink = () => {
  const [previousSendSuccessful, setPreviousSendSuccessful] = useState(false)
  const [send] = useMutation<
    SendProspectDashboardLinkPayload,
    SendProspectDashboardLinkVariables
  >(SEND_DASHBOARD_LINK)

  const sendDashboardLink = async (email: string) => {
    try {
      const res = await send({
        variables: {
          data: {
            email,
          },
        },
      })
      if (res.data) {
        setPreviousSendSuccessful(res.data?.sendProspectDashboardLink)
      }
    } catch {
      setPreviousSendSuccessful(false)
    }
  }

  return {
    previousSendSuccessful,
    sendDashboardLink,
  }
}
